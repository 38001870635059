import Profile from "views/auth/Profile.js";
import Register from "views/auth/Register.js";
import Login from "views/auth/Login.js";
import Forgotpassword from "views/auth/Forgotpassword";
import Resetpassword from "views/auth/Resetpassword";
import Dashboard from "views/user/Dashboard.js";
import BillType from "views/bill_type/index.js";
import About from "views/about/index.js";
import Banner from "views/banner/index.js";
import Camera from "views/camera/index.js";
import MediaManage from "views/media_manage/index.js";
import VideoManage from "views/video_manage/index.js";
import WebsiteConfig from "views/website_config/index.js";



var user_info = {rolename:'default'};
if(user_info != null){
    if(JSON.parse(localStorage.getItem('user-info')) != null){
        user_info = JSON.parse(localStorage.getItem('user-info'));  
    }else{
        user_info.rolename = 'default';
    }
}
console.log("user_info", user_info)
switch (user_info.rolename) {
  case 'admin':
    var routes = [
      {
        path: "/index",
        name: "Dashboard",
        icon: "ni ni-ui-04 text-th",
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/about",
        name: "About",
        icon: "fa fa-solid fa-info",
        component: About,
        layout: "/admin",
      },
      {
        path: "/banner",
        name: "Banner",
        icon: "fa fa-solid fa-flag",
        component: Banner,
        layout: "/admin",
      },
      {
        path: "/camera",
        name: "Camera",
        icon: "fa fa-solid fa-camera",
        component: Camera,
        layout: "/admin",
      },
      {
        path: "/media_manage",
        name: "Media Manage",
        icon: "fa fa-solid fa-forward",
        component: MediaManage,
        layout: "/admin",
      },
      {
        path: "/video_manage",
        name: "Video Manage",
        icon: "fa fa-solid fa-video",
        component: VideoManage,
        layout: "/admin",
      },
      {
        path: "/website_config",
        name: "Website Config",
        icon: "fa fa-solid fa-gear",
        component: WebsiteConfig,
        layout: "/admin",
      },
      {
        path: "/user-profile",
        name: "User Profile",
        icon: "fa fa-solid fa-user",
        component: Profile,
        layout: "/admin",
      }
    ];
    break;
  default:
    var routes = [
      {
        path: "/index",
        name: "Dashboard",
        icon: "ni ni-tv-2 text-th",
        component: Dashboard,
        layout: "/admin",
      },
      {
        path: "/login",
        name: "Login",
        icon: "ni ni-key-25 text-th",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/register",
        name: "Register",
        icon: "ni ni-circle-08 text-th",
        component: Register,
        layout: "/auth",
      },
      {
        path: "/resetpassword",
        name: "Resetpassword",
        icon: "ni ni-circle-08 text-th",
        component: Resetpassword,
        layout: "/auth",
      },
      {
        path: "/forgotpassword",
        name: "Forgotpassword",
        icon: "ni ni-circle-08 text-th",
        component: Forgotpassword,
        layout: "/auth",
      }
    ];

}
 
export default routes;
