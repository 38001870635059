//admins
import Dashboard from "views/user/Dashboard.js";
import Uploads from "views/uploads/Index";
import Profile from "views/auth/Profile.js";

import BillType from "views/bill_type/index.js";
import BillTypeEdit from "views/bill_type/edit.js";

import About from "views/about/index.js";
import AboutEdit from "views/about/edit.js";

import Banner from "views/banner/index.js";
import BannerEdit from "views/banner/edit.js";

import Camera from "views/camera/index.js";
import CameraEdit from "views/camera/edit.js";

import MediaManage from "views/media_manage/index.js";
import MediaManageEdit from "views/media_manage/edit.js";

import VideoManage from "views/video_manage/index.js";
import VideoManageEdit from "views/video_manage/edit.js";

import WebsiteConfig from "views/website_config/index.js";
import WebsiteConfigEdit from "views/website_config/edit.js";


var user_info = {rolename:'default'};
if(user_info != null){
    if(JSON.parse(localStorage.getItem('user-info')) != null){
        user_info = JSON.parse(localStorage.getItem('user-info'));  
    }else{
        user_info.rolename = 'default';
    }
}

 // non visible routes

  switch (user_info.rolename) {
    case 'admin':
      var adminroutes = [
        {
          path: "/index",
          name: "Dashboard",
          icon: "ni ni-tv-2 text-th",
          component: Dashboard,
          layout: "/admin",
        },
        
        // ----------------------- Bill Type Routes--------------------------------------------------------
        {
          path: "/bill_type",
          name: "Bill Type",
          icon: "ni ni-user text-th",
          component: BillType,
          layout: "/admin",
        }, 
        {
          path: "/editbill_type/:id",
          name: "Edit Bill Type",
          icon: "ni ni-user text-th",
          component: BillTypeEdit,
          layout: "/admin",
        },
        // ----------------------- Bill Type Routes--------------------------------------------------------
        // ----------------------- About Routes--------------------------------------------------------
        {
          path: "/about",
          name: "About",
          icon: "ni ni-user text-th",
          component: About,
          layout: "/admin",
        }, 
        {
          path: "/editabout/:id",
          name: "Edit About",
          icon: "ni ni-user text-th",
          component: AboutEdit,
          layout: "/admin",
        },
        // ----------------------- About Routes--------------------------------------------------------
        // ----------------------- Banner Routes--------------------------------------------------------
        {
          path: "/banner",
          name: "Banner",
          icon: "ni ni-user text-th",
          component: Banner,
          layout: "/admin",
        }, 
        {
          path: "/editbanner/:id",
          name: "Edit Bannner",
          icon: "ni ni-user text-th",
          component: BannerEdit,
          layout: "/admin",
        },
        // ----------------------- Banner Routes--------------------------------------------------------
        // ----------------------- Camera Routes--------------------------------------------------------
        {
          path: "/camera",
          name: "Camera",
          icon: "ni ni-user text-th",
          component: Camera,
          layout: "/admin",
        }, 
        {
          path: "/editcamera/:id",
          name: "Edit Camera",
          icon: "ni ni-user text-th",
          component: CameraEdit,
          layout: "/admin",
        },
        // ----------------------- Camera Routes--------------------------------------------------------
        // ----------------------- Media Manage Routes--------------------------------------------------------
        {
          path: "/media_manage",
          name: "Media Manage",
          icon: "ni ni-user text-th",
          component: MediaManage,
          layout: "/admin",
        }, 
        {
          path: "/editmedia_manage/:id",
          name: "Edit Media Manage",
          icon: "ni ni-user text-th",
          component: MediaManageEdit,
          layout: "/admin",
        },
        // ----------------------- Media Manage Routes--------------------------------------------------------
        // ----------------------- Video Manage Routes--------------------------------------------------------
        {
          path: "/video_manage",
          name: "Video Manage",
          icon: "ni ni-user text-th",
          component:VideoManage,
          layout: "/admin",
        }, 
        {
          path: "/editvideo_manage/:id",
          name: "Edit Video Manage",
          icon: "ni ni-user text-th",
          component: VideoManageEdit,
          layout: "/admin",
        },
        // ----------------------- Video Manage Routes--------------------------------------------------------
        // ----------------------- Website Config Routes--------------------------------------------------------
        {
          path: "/website_config",
          name: "Websiten Config",
          icon: "ni ni-user text-th",
          component:WebsiteConfig,
          layout: "/admin",
        }, 
        {
          path: "/editwebsite_config/:id",
          name: "Edit Websiten Config",
          icon: "ni ni-user text-th",
          component: WebsiteConfigEdit,
          layout: "/admin",
        },
        // ----------------------- Video Manage Routes--------------------------------------------------------
        {
          path: "/user-profile",
          name: "User Profile",
          icon: "ni ni-settings-gear-65",
          component: Profile,
          layout: "/admin",
        }

      ];
      break;
    case 'owner':
      var adminroutes = [
        {
          path: "/index",
          name: "Dashboard",
          icon: "ni ni-ui-04 text-th",
          component: Dashboard,
          layout: "/admin",
        },
        {
          path: "/user-profile",
          name: "User Profile",
          icon: "ni ni-settings-gear-65",
          component: Profile,
          layout: "/admin",
        }
      ];
      break;
    case 'client':
      var adminroutes = [
        {
          path: "/index",
          name: "Dashboard",
          icon: "ni ni-tv-2 text-th",
          component: Dashboard,
          layout: "/admin",
        },
        {
          path: "/Uploads",
          name: "Uploads",
          icon: "ni ni-tv-2 text-th",
          component: Uploads,
          layout: "/admin",
        },
        {
          path: "/user-profile",
          name: "User Profile",
          icon: "ni ni-settings-gear-65",
          component: Profile,
          layout: "/admin",
        },
      ];
      break;
    default:
      var adminroutes = [
        {
          path: "/index",
          name: "Dashboard",
          icon: "ni ni-tv-2 text-th",
          component: Dashboard,
          layout: "/admin",
        }
      ];
  
  }

export default adminroutes;
