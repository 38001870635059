import { useState,useEffect } from "react";
import {
  useHistory
} from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
  Input,
  TabContent,
  TabPane,
  Modal,
  ModalBody
} from "reactstrap";
const axios = require("axios");
import { authtoken } from "global.js";
import { apiUrl } from "../../constant";
import { toast } from "react-toastify";
import { billTypeService } from "../../services/billTypeService";


const Index = (props) => {
  const history = useHistory();
  const [bankDocuments, setBankDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [lastPage, setLastPageData] = useState(0);
  const [createModalOpen, setcreateModalOpen] = useState(false);
 const getPaginationData = async (pageNumber = 1,company='') => {
  billTypeService.getWithPaginate(pageNumber,company).then((response) => {
      setBankDetails(response.data.data.data);
      setCurrentPage(response.data.data.currentPage);
      setLastPageData(response.data.data.totalPage);
    });
  }

   const handlePageClick = (e, index) => {
    e.preventDefault();
    getPaginationData(index);
  };

  const handlePreviousClick = () => {
    getPaginationData(prev);
  };

  const handleNextClick = () => {
    getPaginationData(next);
  };

  const tableHeader = billTypeService.tableHeader() ?? [];
  
  console.log("tableHeader", tableHeader)
  useEffect(() => {
    getPaginationData()
  }, []);


const initialStateData = billTypeService.initialFormData();

const [inputFields, setInputfields] = useState(initialStateData);
function onRespondClick(){
  var datasend = billTypeService.transformFormData(inputFields)
    axios({
      method: "post",
      url: `${apiUrl}/bills_type/create`,
      data: datasend,
      headers: { Authorization: `${authtoken}` },
    })
      .then(
        function (response) {
          console.log(response)
          if (response.status == 200) {
            setInputfields(initialStateData)
            setcreateModalOpen(false)
            toast.success(response.data.message);
            getPaginationData()
          }else{
            toast.error(response.data.message);
          }
        },
        (error) => {
          return error.response;
        }
      )
      .catch(function (error) {});
  }
  function inputhandler(e){
    setInputfields({ ...inputFields, [e.target.name]: e.target.value });
  }
  const deleterow = (e) => {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this data!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((deletdata) => {
        if(deletdata){
          billTypeService.deleteById(e).then((response) => {
            if(response.data.code == 200){
              const newList = bankDocuments.filter((item) => item._id !== e);
              setBankDetails(newList);
              toast.success('Deleted Successfully');
            }else{
              toast.error('Failed');
            }
          });
        }
      });

  };
  const handleclick = (e) => {
     history.push({
      pathname: 'editbill_type/'+e,
    });
  }

  return (
    <>
       <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>
      {/* Page content */}
      
      <Container className="mt--7" fluid>

        <Row>
       
        <Col className="mb-5 mb-xl-0" xl="12">
        <Card className="shadow">
          <CardHeader>
          <div className="align-items-center row">
            <div className="col-8">
              </div>
              <div className="text-right col-4"> <button className="btn btn-info btn-sm" onClick={() => setcreateModalOpen(true) } > Create {billTypeService.moduleTitle()}</button></div>
          </div>
          </CardHeader>
          <CardBody>
          
            <TabContent activeTab={"tabs1"}>
              <TabPane tabId={"tabs1"}>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    {
                        tableHeader.map((item, i) => (
                          <th scope="col">{item.title}</th>
                        ))
                    }
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                {bankDocuments.length > 0 && bankDocuments  ? (
                      bankDocuments.map((trans, i) => (
                        <tr  key={i}  >
                          {
                            tableHeader.map((item, value) => (
                              <th>{trans[item.value]}</th>
                            ))
                          }
                          <td><> <Button className="btn btn-success" onClick={handleclick.bind(this, trans._id)} ><i className="fa fa-edit"></i></Button><Button className="btn btn-danger" onClick={deleterow.bind(this, trans._id)} ><i className="fa fa-trash"></i></Button></></td>
                        </tr>
                      ))
                  ) : (
                   <tr>
                   <td>No Data Found</td>
                   </tr>
                 )}
                </tbody>
              </Table>
              </TabPane>
            
              
            </TabContent>
            <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem disabled={currentPage <= 1}>
                      <PaginationLink onClick={handlePreviousClick} previous />
                    </PaginationItem>
                    {[...Array(lastPage)].map((page, i) => (
                      <PaginationItem
                        active={i + 1 === currentPage}
                        key={i + 1}
                      >
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, i + 1)}
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage >= lastPage}>
                      <PaginationLink onClick={handleNextClick} next />
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
          </CardBody>
        </Card>
        </Col>
        </Row>
        
      </Container>
      <Modal className="taskdetailmodal"  isOpen={createModalOpen} scrollable={true}>
            <div className=" modal-header">
              <h5 className=" modal-title" id="exampleModalLabel">
              Create {billTypeService.moduleTitle()}
              </h5>
              <button aria-label="Close" className=" close" type="button" onClick={() => setcreateModalOpen(!createModalOpen)}>
                <span aria-hidden={true}>×</span>
                <div>
                  
                </div>
              </button>
            </div>
            <ModalBody>
            <Row>
              {
                tableHeader.map((item, value) => (
                    <Col lg={item.colWidth}>
                       <FormGroup>
                                <label className="form-control-label">
                                  {item.title}
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  defaultValue={inputFields[item.value]}
                                  id="input-name"
                                  placeholder={item.title}
                                  name={item.value}
                                  type="text"
                                  onChange={inputhandler}
                                />
                              </FormGroup>
                      </Col>
                ))
              }        
            </Row>

                  <FormGroup>
                    <br></br>
                  <Button color='success' size="extra-small" onClick={onRespondClick.bind(this)}>
                      Create {billTypeService.moduleTitle()}
                    </Button>
                  </FormGroup>
            </ModalBody>
      </Modal>
    </>
  );
};

export default Index;