import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  UncontrolledAlert,
  TabContent,
  TabPane
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import React, { useState, useEffect } from "react";
import { userService } from "../../services/authentication";
import "assets/css/custom.css";
import { ToastContainer, toast } from "react-toastify";
import { Formloader } from "components/Loader/PageLoader.js";

const axios = require("axios");

const Profile = () => {
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  const [error, setError] = useState(null);
  const [successMsg, setsuccessMsg] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const initialData = {
    name: "",
    enmail: "",
    phone: ""
  }
  const [inputFields, setInputfields] = useState({});
  const [comapanydetails, setComapanydetails] = useState({});
  const [formloader, setformloader] = useState(false);
  const [tabs, setTabs] = useState(1);
  const [profilecheck, setProfilecheck] = useState(false);
  const errorDiv = error ? (
    <UncontrolledAlert className="alert-danger" fade={false}>
      {error}
    </UncontrolledAlert>
  ) : (
    ""
  );
  const successDiv = successMsg ? (
    <UncontrolledAlert className="alert-success" fade={false}>
      {successMsg}
    </UncontrolledAlert>
  ) : (
    ""
  );



  function inputhandler(e) {
    setInputfields({ ...inputFields, [e.target.name]: e.target.value });
  }
  const get_staff_data = async () => {
    // staffmanageService.getstaff(user_info.id).then((response) => {
    setInputfields(user_info);
    // setSrc(response.data.data.profilePic);

    // setSrc(response.data.data.profilePic)
    // });
  };

  useEffect(() => {
    get_staff_data();
  }, [profilecheck]);

  function profileupdate(e) {
    console.log(inputFields)
    e.preventDefault();
    setformloader(true);
    userService
      .updateprofile(
        inputFields
      )
      .then((response) => {
        // console.log(response);
        if (response.status == 200) {
          setformloader(false);
          toast.success(response.data.message);
        } else {
          setformloader(false);
          toast.error(response.data.message);
          // setError('Enter All required field');
          setsuccessMsg(null);
          // alert(response.data.message);
        }
      });
  }

  return (
    <>
      <UserHeader />
      {/* Page content */}

      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1 mb-5 mb-xl-0" xl="2">

          </Col>
          <Col className="order-xl-2" xl="8">

            <Card className="">
              <CardBody>
                <TabContent activeTab={"tabs" + tabs}>
                  <TabPane tabId="tabs1">
                    <Card className="bg-secondary">
                      <CardHeader className="bg-white border-0">
                        <Row className="align-items-center">
                          <Col xs="8">
                            <h3 className="mb-0">My account</h3>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Form>
                          <div className="pl-lg-4">
                            <Row>
                              {errorDiv}
                              {successDiv}
                              <Form onSubmit={profileupdate}>

                                <div className="pl-lg-4">
                                  <Row className="justify-content-md-center">

                                    <Col lg="12">
                                      <FormGroup>
                                        <label
                                          className="form-control-label"
                                          htmlFor="input-fname"
                                        >
                                          Name
                                        </label>
                                        <Input
                                          className="form-control-alternative"
                                          defaultValue={inputFields.name}
                                          id="input-name"
                                          placeholder=" Name"
                                          name="name"
                                          type="text"
                                          onChange={inputhandler}

                                        />
                                      </FormGroup>
                                    </Col>

                                    <Col lg="12">
                                      <FormGroup>
                                        <label
                                          className="form-control-label"
                                          htmlFor="input-email"
                                        >
                                          Email address
                                        </label>
                                        <Input
                                          className="form-control-alternative"
                                          id="input-email"
                                          defaultValue={inputFields.email}
                                          placeholder="Email"
                                          type="email"
                                          name="email"
                                          onChange={inputhandler}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col lg="12">
                                      <FormGroup>
                                        <label
                                          className="form-control-label"
                                          htmlFor="input-email"
                                        >
                                          Phone no.
                                        </label>
                                        <Input
                                          className="form-control-alternative"
                                          id="input-email"
                                          defaultValue={inputFields.phone}
                                          placeholder="Phone no."
                                          type="telephone"
                                          name="phone"
                                          onChange={inputhandler}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col lg="12">
                                      <FormGroup>
                                        <label className="form-control-label" htmlFor="input-first-name">
                                          New Password
                                        </label>
                                        <div className="input-group">
                                          <Input
                                            className="form-control-alternative"
                                            defaultValue=""
                                            id="input-first-name"
                                            placeholder="Must be at least 10 characters long"
                                            onChange={inputhandler}
                                            type={passwordVisible ? "text" : "password"}
                                            name="password"
                                            required
                                          />
                                          <div className="input-group-append">
                                            <span
                                              className="input-group-text "
                                              style={{ cursor: 'pointer' }}
                                              onClick={() => setPasswordVisible(!passwordVisible)}
                                            >
                                              {passwordVisible ?  <i className="fa fa-eye" ></i>: <i className="fa fa-eye-slash"></i> }
                                            </span>
                                          </div>
                                        </div>
                                      </FormGroup>
                                    </Col>

                                  </Row>
                                  <div className="text-center"> 
                                    <button
                                      type="submit"
                                      className="btn btn-success mt-4"
                                      onClick={profileupdate}
                                      disabled={formloader}
                                    >
                                      Update{" "}
                                      {formloader ? (
                                        <Formloader
                                          height={20}
                                          color="#ffffff"
                                        ></Formloader>
                                      ) : (
                                        <></>
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </Form>
                            </Row>
                          </div>
                        </Form>
                      </CardBody>
                    </Card>
                  </TabPane>

                </TabContent>
              </CardBody>
            </Card>
          </Col>

        </Row>
      </Container>
    </>
  );
};

export default Profile;

