import { useState, useEffect } from "react";
import {
  useHistory
} from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
  Input,
  TabContent,
  TabPane,UncontrolledTooltip,
  Modal,
  ModalBody
} from "reactstrap";
const axios = require("axios");
import { authtoken } from "global.js";
import { apiUrl } from "../../constant";
import { toast } from "react-toastify";
import { mediaManageService } from "../../services/mediaManageService";


const Index = (props) => {
  const history = useHistory();
  const [bankDocuments, setBankDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [lastPage, setLastPageData] = useState(0);
  const [createModalOpen, setcreateModalOpen] = useState(false);
  const getPaginationData = async (pageNumber = 1, company = '') => {
    mediaManageService.getWithPaginate(pageNumber, company).then((response) => {
      setBankDetails(response.data.data.data);
      setCurrentPage(response.data.data.currentPage);
      setLastPageData(response.data.data.totalPage);
    });
  }

  const handlePageClick = (e, index) => {
    e.preventDefault();
    getPaginationData(index);
  };

  const handlePreviousClick = () => {
    getPaginationData(prev);
  };

  const handleNextClick = () => {
    getPaginationData(next);
  };

  const tableHeader = mediaManageService.tableHeader() ?? [];

  console.log("tableHeader", tableHeader)
  useEffect(() => {
    getPaginationData()
  }, []);


  const initialStateData = mediaManageService.initialFormData();

  const [inputFields, setInputfields] = useState(initialStateData);
  function onRespondClick() {
    var datasend = mediaManageService.transformFormData(inputFields)
    axios({
      method: "post",
      url: `${apiUrl}/media_manage/create`,
      data: datasend,
      headers: { Authorization: `${authtoken}` },
    })
      .then(
        function (response) {
          console.log(response)
          if (response.status == 200) {
            setInputfields(initialStateData)
            setSelectedFile(null);
            setPreviewImage(null);
            setcreateModalOpen(false)
            toast.success(response.data.message);
            getPaginationData()
          } else {
            toast.error(response.data.message);
          }
        },
        (error) => {
          return error.response;
        }
      )
      .catch(function (error) { });
  }

  const inputhandler = (e) => {
    if (e.target.type === "file") {
      handleFileChange(e);
    } else {
      setInputfields({ ...inputFields, [e.target.name]: e.target.value });
    }
  };


  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);

    // Generate a preview of the image
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const deleterow = (e) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((deletdata) => {
        if (deletdata) {
          mediaManageService.deleteById(e).then((response) => {
            if (response.data.code == 200) {
              const newList = bankDocuments.filter((item) => item._id !== e);
              setBankDetails(newList);
              toast.success('Deleted Successfully');
            } else {
              toast.error('Failed');
            }
          });
        }
      });

  };
  const handleclick = (e) => {
    history.push({
      pathname: 'editmedia_manage/' + e,
    });
  }

  // -----------------------Image Style ------------------------
  const chooseFileButtonStyle = {
    display: 'inline-block',
    backgroundColor: '#2DCE89',
    color: 'white',
    fontweight: "bold",
    padding: '5px 15px',
    borderRadius: '30px',
    cursor: 'pointer',

  };

  const fileInputStyle = {
    display: 'none', // Hide the default file input
  };

  const imagePreviewContainerStyle = {
    marginTop: '12px',
    textAlign: 'center',
  };

  const imagePreviewStyle = {
    maxWidth: '120px',
    borderRadius: '50%', // Make the image round
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add a subtle shadow
  };
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>
      {/* Page content */}

      <Container className="mt--7" fluid>

        <Row>

          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
            <CardHeader>
                <div className="align-items-center row">
                  <div className="col-8">
                    <Col lg="8" md="8" sm="8">
                      <h3 className="mb-0">Media Manage </h3>
                    </Col>
                  </div>
                  <div className="text-right col-4">
                    <button
                      className="btn btn-sm" style={{ backgroundColor: "#5e72e4", color: "#fff  " }}
                      onClick={() => setcreateModalOpen(true)}
                    >
                      Create {mediaManageService.moduleTitle()}
                    </button>
                  </div>
                </div>
              </CardHeader>
              <CardBody>

                <TabContent activeTab={"tabs1"}>
                  <TabPane tabId={"tabs1"}>
                    <Table className="align-items-center table-flush" responsive>
                      <thead className="thead-light">
                        <tr>
                          {
                            tableHeader.map((item, i) => (
                              <th scope="col">{item.title}</th>
                            ))
                          }
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bankDocuments.length > 0 && bankDocuments ? (
                          bankDocuments.map((trans, i) => (
                            <tr key={i}  >
                              {
                                tableHeader.map((item, value) => (
                                  <th>{trans[item.value]}</th>
                                ))
                              }
                              <td>
                                <>
                                  <Button
                                    style={{ padding: '5px 8px', fontSize: '12px', width: "30px", height: "30px" }}
                                    className="btn btn-success"
                                    onClick={() => handleclick(trans._id)}
                                  >
                                    <i className="fa fa-edit"></i>
                                  </Button>
                                  <Button
                                    style={{ padding: '5px 8px', fontSize: '12px', width: "30px", height: "30px" }}
                                    className="btn btn-danger"
                                    onClick={() => deleterow(trans._id)}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </Button>
                                </>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td>Loading......</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </TabPane>


                </TabContent>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem disabled={currentPage <= 1}>
                        <PaginationLink onClick={handlePreviousClick} previous />
                      </PaginationItem>
                      {[...Array(lastPage)].map((page, i) => (
                        <PaginationItem
                          active={i + 1 === currentPage}
                          key={i + 1}
                        >
                          <PaginationLink
                            onClick={(e) => handlePageClick(e, i + 1)}
                          >
                            {i + 1}
                          </PaginationLink>
                        </PaginationItem>
                      ))}
                      <PaginationItem disabled={currentPage >= lastPage}>
                        <PaginationLink onClick={handleNextClick} next />
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </CardBody>
            </Card>
          </Col>
        </Row>

      </Container>
      <Modal className="taskdetailmodal" isOpen={createModalOpen} scrollable={true} style={{ width: "30%" }}>
        <div className=" modal-header">
          <h5 className=" modal-title" id="exampleModalLabel">
            Create {mediaManageService.moduleTitle()}
          </h5>
          <button aria-label="Close" className=" close" type="button" onClick={() => setcreateModalOpen(!createModalOpen)}>
            <span aria-hidden={true}>×</span>
            <div>

            </div>
          </button>
        </div>
        <ModalBody>
          <Row>
            {tableHeader.map((item, index) => (
              <Col key={index} lg={item.colWidth}>
                <FormGroup>
                  <Row>
                    <Col >
                      <label className="form-control-label">
                        {item.title}
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {item.type === 'IMAGE' ? (
                        <div>
                          <label htmlFor="fileInput" style={chooseFileButtonStyle}>
                            Choose File
                          </label>
                          <Input
                            id="fileInput"
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                            style={fileInputStyle}
                          />
                          {previewImage && (
                            <div style={imagePreviewContainerStyle}>
                              <img
                                src={previewImage}
                                alt="Preview"
                                style={imagePreviewStyle}
                              />
                            </div>
                          )}
                        </div>
                      ) : item.type === 'SELECT' ? (  // Check if the type is 'SELECT'
                        <Input
                          type="select"
                          className="form-control-alternative"
                          value={inputFields[item.value]}
                          onChange={inputhandler}
                          name={item.value}
                        >
                          <option value="active" selected>Active</option>
                          <option value="inactive">Inactive</option>
                          {item.options?.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Input>
                      ) : (
                        <Input
                          className="form-control-alternative"
                          defaultValue={inputFields[item.value]}
                          id={`input-${item.value}`}
                          placeholder={item.title}
                          name={item.value}
                          type="text"
                          onChange={inputhandler}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {/* Additional content or status rendering can be added here */}
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            ))}
          </Row>

          <FormGroup>
            <br></br>
            <Button color='success' size="extra-small" onClick={onRespondClick.bind(this)}>
              Create {mediaManageService.moduleTitle()}
            </Button>
          </FormGroup>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Index;