import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useParams, useHistory } from "react-router-dom";
import UserHeader from "components/Headers/UserHeader.js";
import React, { useState, useEffect } from "react";
import { cameraService } from "../../services/cameraService";
import 'assets/css/custom.css';
import { toast } from 'react-toastify';
import { Formloader } from 'components/Loader/PageLoader.js';

const edit = () => {
  let { id } = useParams();
  const [inputFields, setInputfields] = useState({});
  const history = useHistory();
  const [formloader, setformloader] = useState(false);

  const tableHeader = cameraService.tableHeader() ?? [];
  useEffect(() => {
    cameraService.getById(id).then((response) => {
      setInputfields(response.data.data);
    });
  }, [id]);

  function inputhandler(e) {
    setInputfields({ ...inputFields, [e.target.name]: e.target.value });
  }

  function formUpdate(e) {
    e.preventDefault();
    setformloader(true);
    console.log("form input", inputFields);
    cameraService.updateById(inputFields).then((response) => {
      if (response.data.code === 200) {
        setformloader(false);
        toast.success('Updated Successfully!');
        history.push("/banner");
      } else {
        setformloader(false);
        toast.error('Failed');
      }
    });
  }
  // -----------------------Image Style ------------------------
  const chooseFileButtonStyle = {
    display: 'inline-block',
    backgroundColor: '#2DCE89',
    color: 'white',
    fontweight: "bolder",
    padding: '10px 20px',
    borderRadius: '30px',
    cursor: 'pointer',
    marginRight: "10px",
  };

  const fileInputStyle = {
    display: 'none', // Hide the default file input
  };
  const imagePreviewStyle = {
    // maxWidth: '120px',
    borderRadius: '50%',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    maxWidth: "100%",
    maxHeight: "150px",
    // marginBottom: "10px",
  };
  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row className="justify-content-md-center">
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardBody>
                <Form onSubmit={formUpdate}>
                  <h6 className="heading-small text-muted mb-4">
                    {cameraService.moduleTitle()}
                  </h6>
                  {Object.keys(inputFields).length > 0 ? ( // Conditionally render the form content
                    <div className="pl-lg-4">
                    <Row>
                      {tableHeader.map((item, value) => (
                        <Col lg={item.colWidth} key={value}>
                          <FormGroup>
                            <label className="form-control-label">
                              {item.title}
                            </label>
                            {item.type === "IMAGE" ? (
                              <div>
                                <label htmlFor={`fileInput-${item.value}`} style={chooseFileButtonStyle}>
                                  Choose File
                                </label>
                                <img
                                  src={inputFields[item.value]}
                                  alt="Preview"
                                  style={imagePreviewStyle}
                                />
                                <Input  
                                  type="file"
                                  accept="image/*"
                                  style={fileInputStyle}
                                  id={`fileInput-${item.value}`}
                                  onChange={(e) => {
                                    const file = e.target.files[0];
                                    const reader = new FileReader();
                                    reader.onloadend = () => {
                                      setInputfields({
                                        ...inputFields,
                                        [item.value]: reader.result,
                                      });
                                    };
                                    if (file) {
                                      reader.readAsDataURL(file);
                                    }
                                  }}
                                />
                              </div>
                            ) : item.type === "SELECT" ? (
                              <Input
                                type="select"
                                className="form-control-alternative"
                                defaultValue={inputFields[item.value]}
                                name={item.value}
                                onChange={inputhandler}
                              >
                                <option value="Active" >Active</option>
                                {/* <option value="INACTIVE">Inactive</option> */}
                                {item.options?.map((option, index) => (
                                  <option key={index} value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </Input>
                            ) : (
                              <Input
                                className="form-control-alternative"
                                defaultValue={inputFields[item.value]}
                                id="input-name"
                                placeholder={item.title}
                                name={item.value}
                                type="text"
                                onChange={inputhandler}
                              />
                            )}
                          </FormGroup>
                        </Col>
                      ))}
                    </Row>
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-success mt-4"
                          onClick={formUpdate}
                          disabled={formloader}
                        >
                          Update{' '}
                          {formloader ? (
                            <Formloader height={20} color="#ffffff" />
                          ) : (
                            <></>
                          )}
                        </button>
                      </div>
                    </div>
                  ) : (
                    <p>Loading...</p>
                  )}
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default edit;
