import { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  Container,
  Row,
  Col
} from "reactstrap";
import { dashboardServices } from "../../services/dashboardServices.js";

const Index = (props) => {
  const [cards, setCards] = useState([]);

  function get_dashboard_data() {
    dashboardServices.getDashboard().then((response) => {
      console.log(response.data.data);
    });
  }

  useEffect(() => {
    get_dashboard_data();
  }, []);

  const cardDesign = {
    main: {
      width: "100%",
      margin: "10px 0", 
      borderLeft: "6px solid #7A69F0",
    },
    mainHover: {
      width: "100%",
      margin: "10px 0", 
      borderLeft: "6px solid #7A69F0",
      boxShadow: "0px 0px 5px 0px rgb(122, 105, 240)",
    },
    cardValue: { fontSize: "35px" },
    cardSubtitle: { fontSize: "12px" },
    cardIcon: { color: "#675cff", fontSize: "40px" },
  };

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col xs={12} xl={2}>
            <h2>Dashboard</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} md={3} className="mb-4">
            <Card style={cardDesign.main}>
              <CardBody>
                <Row>
                  <Col xs={8}>
                    <CardText tag="h5" style={cardDesign.cardValue}>
                      1000
                    </CardText>
                    <CardSubtitle tag="h5" style={cardDesign.cardSubtitle}>
                      Total Owner
                    </CardSubtitle>
                  </Col>
                  <Col xs={4} className="text-center">
                    <i
                      className="fa fa-solid fa-users"
                      style={cardDesign.cardIcon}
                    ></i>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={3} className="mb-4">
            <Card style={cardDesign.main}>
              <CardBody>
                <Row>
                  <Col xs={8}>
                    <CardText tag="h5" style={cardDesign.cardValue}>
                      1000
                    </CardText>
                    <CardSubtitle tag="h5" style={cardDesign.cardSubtitle}>
                      Total Owner
                    </CardSubtitle>
                  </Col>
                  <Col xs={4} className="text-center">
                    <i
                      className="fa fa-solid fa-users"
                      style={cardDesign.cardIcon}
                    ></i>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={3} className="mb-4">
            <Card style={cardDesign.main}>
              <CardBody>
                <Row>
                  <Col xs={8}>
                    <CardText tag="h5" style={cardDesign.cardValue}>
                      1000
                    </CardText>
                    <CardSubtitle tag="h5" style={cardDesign.cardSubtitle}>
                      Total Owner
                    </CardSubtitle>
                  </Col>
                  <Col xs={4} className="text-center">
                    <i
                      className="fa fa-solid fa-users"
                      style={cardDesign.cardIcon}
                    ></i>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={3} className="mb-4">
            <Card style={cardDesign.main}>
              <CardBody>
                <Row>
                  <Col xs={8}>
                    <CardText tag="h5" style={cardDesign.cardValue}>
                      1000
                    </CardText>
                    <CardSubtitle tag="h5" style={cardDesign.cardSubtitle}>
                      Total Owner
                    </CardSubtitle>
                  </Col>
                  <Col xs={4} className="text-center">
                    <i
                      className="fa fa-solid fa-users"
                      style={cardDesign.cardIcon}
                    ></i>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>  
    </>
  );
};

export default Index;
``
