
const axios = require("axios");
import { authtoken } from "../global.js";
import { apiUrl } from "../constant.js";

export const fileUpload = async (files) =>{
    try {
        let urls = [];
        for await (let file of files){
            const formData = new FormData();
            formData.append("image", file);
            let data = await axios({ method: "post", url: `${apiUrl}/upload`, data: formData, headers: { Authorization: `${authtoken}` }});
            console.log("data inside fileUpload", data);
            urls.push(data?.data?.data);
        }
        return urls;
    } catch (error) {
        console.log("error in fileUpload", error);
        throw error;
    }
}

export const filesUpload = (files) =>{
    
}