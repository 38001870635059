import { apiUrl } from '../constant';
import { authtoken } from 'global.js';
const axios = require("axios");
export const bannerService = {
  getWithPaginate,
  getById,
  deleteById,
  updateById,
  transformFormData,
  initialFormData,
  tableHeader,
  moduleTitle,
};

function transformFormData(data = {}) {
  return {
    "title": data?.title,
    "image": data?.image,
    "status": data?.status,
  };
}

function moduleTitle() {
  return "Banner";
}

function initialFormData() {
  return {
    "title": "",
    "image": "",
    "status": "",
  }
}

function tableHeader() {
  return [
    { title: "Title", value: "title", type: 'TEXT', colWidth: 12 },
    { title: "Image", value: "image", type: 'FILE', colWidth: 12},
    { title: "Status", value: "status", type: 'SELECT', colWidth: 12 },
  ]
};

function getWithPaginate(page, company) {
  let filters = '';
  if (company) {
    filters = `&search=${company}`;
  }
  return axios({
    method: "get",
    url: `${apiUrl}/banner/get_with_paginate?limit=10&currentPage=${page}${filters}`,
    headers: { Authorization: `${authtoken}` },
  })
    .then(function (response) {
      if (response.status == 200) {
        return response;
      }
    }, error => {
      return error.response;
    })
    .catch(function (error) {
    });
}

function getById(id) {

  return axios({
    method: "get",
    url: `${apiUrl}/banner/${id}`,
    headers: { Authorization: `${authtoken}` },
  })
    .then(function (response) {
      if (response.status == 200) {
        return response;
      }
    }, error => {
      return error.response;
    })
    .catch(function (error) {
    });
}

function deleteById(id) {
  return axios({
    method: "delete",
    url: `${apiUrl}/banner/delete/${id}`,
    headers: { Authorization: `${authtoken}` },
  })
    .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });
}

function updateById(data) {
  var datasend = JSON.stringify(transformFormData(data));
  return axios({
    method: "put",
    url: `${apiUrl}/banner/update/${data._id}`,
    data: datasend,
    headers: { Authorization: `${authtoken}`, 'Content-Type': 'application/json' },
  })
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      return response;
    });
}
