import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Row, Col, UncontrolledAlert } from "reactstrap";
import { userService } from '../../services/authentication';
import { ToastContainer, toast } from 'react-toastify';

const Login = () => {
  const history = useHistory();

  const [inputFieldEmail, setInputemail] = useState({
    email: "",
  });

  const [inputFieldpass, setInputpassword] = useState({
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <UncontrolledAlert className="alert-danger" fade={false}>
      {error}
    </UncontrolledAlert>
  ) : (
    ""
  );

  const authenticate = (e) => {
    e.preventDefault();
    var email = inputFieldEmail.email;
    var pass = inputFieldpass.password;
    if (email === '' || pass === '') {
      toast.error('All fields are Required !');
      return false;
    }
    userService.login(inputFieldEmail.email, inputFieldpass.password).then((response) => {
      if (response?.status === 200) {
        toast.success(response.data.message);
      } else {
        toast.error("Invalid email or password!!!");
      }
    });
    return;
  }

  const inputsHandleremail = (e) => {
    setInputemail({ [e.target.name]: e.target.value });
  };

  const inputsHandlerpass = (e) => {
    setInputpassword({ [e.target.name]: e.target.value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <ToastContainer />
          <CardBody className="px-lg-5 py-lg-5" >
            <div className="text-center text-muted mb-4">
              <br></br> <br></br>
              <h1>Admin</h1>
              <br></br>
            </div>
            {errorDiv}
            <Form id="login-form" role="form" onSubmit={(e) => authenticate(e)}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    name="email"
                    autoComplete="new-email"
                    onChange={inputsHandleremail}
                    required
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    autoComplete="new-password"
                    onChange={inputsHandlerpass}
                    required
                  />
                  <InputGroupAddon addonType="append">
                    <Button color="secondary" onClick={togglePasswordVisibility}>
                      {showPassword ? "Hide" : "Show"}
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button className="my-4 col-12" color="primary" type="submit">
                  <span>Sign in</span>
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-6">
          <Col xs="6">

          </Col>
          <Col className="text-right" xs="6">

          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Login;
