import {apiUrl} from '../constant';
import {authtoken} from 'global.js';
const axios = require("axios");
export const billTypeService = {
  getWithPaginate,
  getById,
  deleteById,
  updateById,
  transformFormData,
  initialFormData,
  tableHeader,
  moduleTitle
};

function transformFormData (data = {}){
  return {
    "bill_type": data?.bill_type,
    "status":'active'
  };
}

function moduleTitle() {
  return "Bill Type";
}

function initialFormData() {
    return {
      "bill_type": "",
      "status":'active'
  }
}

function tableHeader() {return [
    { title:"Bill Type", value:"bill_type", type: 'TEXT', colWidth: 6},
]};

function getWithPaginate(page,company){
  let filters = '';
  if(company){
     filters = `&search=${company}`;
  }
  return axios({
      method: "get",
      url: `${apiUrl}/bills_type/get_with_paginate?limit=10&currentPage=${page}${filters}`,
      headers: {Authorization: `${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response; 
      })
      .catch(function (error) {
      });      
}

function getById(id){
  
  return axios({
      method: "get",
      url: `${apiUrl}/bills_type/${id}`,
      headers: {Authorization: `${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response; 
      })
      .catch(function (error) {
      });      
}

function deleteById(id){
  return axios({
    method: "delete",
    url: `${apiUrl}/bills_type/delete/${id}`,
    headers: { Authorization: `${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}

function updateById(data){
  var datasend = JSON.stringify(transformFormData(data));
  return axios({
      method: "put",
      url: `${apiUrl}/bills_type/update/${data._id}`,
      data: datasend,
      headers: {Authorization: `${authtoken}`,'Content-Type': 'application/json' },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (response) {
      return response;
    }); 
}