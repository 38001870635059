import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useParams } from "react-router-dom";
import UserHeader from "components/Headers/UserHeader.js";
import React, { useState, useEffect } from "react";
import { aboutService } from '../../services/aboutService';
import 'assets/css/custom.css';
import { toast } from 'react-toastify';
import { Formloader } from 'components/Loader/PageLoader.js';

const edit = () => {
  let { id } = useParams();
  const [inputFields, setInputfields] = useState({});

  const [formloader, setformloader] = useState(false);

  const tableHeader = aboutService.tableHeader() ?? [];
  useEffect(() => {
    aboutService.getById(id).then((response) => {
      setInputfields(response.data.data);
    });
  }, []);

  function inputhandler(e) {
    setInputfields({ ...inputFields, [e.target.name]: e.target.value });
  }

  function formUpdate(e) {
    e.preventDefault();
    setformloader(true);
    console.log("form input", inputFields)
    aboutService.updateById(inputFields).then((response) => {
      if (response.data.code == 200) {
        setformloader(false);
        toast.success('Updated Succesfully!');
      } else {
        setformloader(false);
        toast.error('Failed');
      }
    });
  }
  return (
    <>

      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>

        <Row className="justify-content-md-center">

          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">

              <CardBody>

                <Form onSubmit={formUpdate}>
                  <h6 className="heading-small text-muted mb-4">
                    {aboutService.moduleTitle()}
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      {
                        tableHeader.map((item, value) => (
                          <Col lg={item.colWidth}>
                            <FormGroup>
                              <label className="form-control-label">
                                {item.title}
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={inputFields[item.value]}
                                id="input-name"
                                placeholder={item.title}
                                name={item.value}
                                type="text"
                                onChange={inputhandler}
                              />
                            </FormGroup>
                          </Col>
                        ))
                      }
                    </Row>
                    <div className="text-center">
                      <button type="submit" className="btn btn-success mt-4" onClick={formUpdate} disabled={formloader}>
                        Update  {formloader ? (<Formloader height={20} color='#ffffff'></Formloader>) : (<></>)}
                      </button>
                    </div>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default edit;
