import axios from "axios";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import DataTable from "react-data-table-component";
import { apiUrl, domainUrl } from "../../constant";
import Moment from "moment";
// import './style.css';
import { tableCustomStyles } from "./tableStyle";
import "./style.css";
import swal from 'sweetalert';
import { toast } from "react-toastify";

const singlefiledelete = (e) =>{
  swal({
    title: "Are you sure?",
    text: "Once deleted, you will not be able to recover this uploaded file!",
    icon: "warning",
    buttons: true,
    dangerMode: true,
  })
  .then((willDelete) => {
    if (willDelete) {
      var bodyFormData = new FormData();
      const token = localStorage.getItem("authtoken");
        bodyFormData.append("listOfFilesToBeDeleted[]", e);
      return axios({
        method: "post",
        url: `${apiUrl}/deleteuploadsfile`,
        data: bodyFormData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(
          function (response) {
            console.log(response);
            if (response.status == 200) {
              return  toast.success("File has been deleted successfully");
              fetchrecentuploads
            } else {
              return toast.error(response.message);
            }
          },
          (error) => {
            toast.error(error);
          }
        )
        .catch(function (error) {
          toast.error(error);
        });
    } else {
      // swal("Your imaginary file is safe!");
    }
  });
  }


const columns = [
  {
    name: "File Name ",
    id: "filename",
    selector: (row) => row.file_name,
    sortable: true,
  },
  {
    name: "File Type",
    id: "filetype",
    selector: (row) => row.file_type,
    sortable: true,
  },
  {
    name: "File Status",
    id: "filestatus",
    selector: (row) => row.file_case_status,
    sortable: true,
  },
  {
    name: "Upload Date",
    id: "uploaddate",
    selector: (row) => Moment(row.updated_at).format("DD-MM-YYYY h:mm:ss a"),
    sortable: true,
  },
  {
    name: "Action",
    id: "action",
    button: true,
    cell: (row) => (
      (row.file_case_status != null) ?
      (row.file_case_status.toLowerCase() ==="invoice processed" || row.file_case_status.toLowerCase() ==="invoice in queries") ? 
      <button disabled>Delete</button>
      : 
      <button  className="delete_btn" onClick={()=> singlefiledelete(row.id)}>
        Delete
      </button>
      
      : null
    ),
  },
];


function uploadsfilelist(props) {
  const [uploadslist, setUploadslist] = useState([]);
  const [deleteselected, setDeleteselected] = useState(false);
  const [search, setSearch] = useState([]);
  const [filters, setFilters] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [number, setNumber] = useState(0);
  const token = localStorage.getItem("authtoken");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };



  const handleChange = ({ selectedRows }) => {
    
    if (selectedRows.length > 0) {
      setDeleteselected(true);
      setSelectedRows(selectedRows);
    } else {
      setDeleteselected(false);
      setSelectedRows([]);
    }
  };

  // Toggle the state so React Data Table changes to clearSelectedRows are triggered
  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
  };

  const fetchrecentuploads = () => {
      return axios.get(`${apiUrl}/getuploadfile`, config).then((response) => {
        setUploadslist(response.data.data), setFilters(response.data.data)
      });

  };

  useEffect(() => {
    fetchrecentuploads();
  }, [props.data]);

  useEffect(() => {
    const result = uploadslist.filter((r) => {
      return r.file_name.toLowerCase().match(search.toLowerCase());
    });
    setFilters(result);
  }, [search]);

  const deleteselecteditem = () => {

    if(selectedRows.length>0){
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this uploaded file!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          console.log(selectedRows);
          deleteuploadedfile();
          setDeleteselected(false);
          handleClearRows();
          fetchrecentuploads();
          // swal("Your uploads file has been deleted!", {
          //   icon: "success",
          // });
        } else {
          // swal("Your imaginary file is safe!");
        }
      });
    }else{
      alert("Please check any check")
    }

  };


const deleteuploadedfile=()=>{
    var bodyFormData = new FormData();
    const token = localStorage.getItem("authtoken");
    for (let i = 0; i < selectedRows.length; i++) {
      bodyFormData.append("listOfFilesToBeDeleted[]", selectedRows[i].id);
    }
    return axios({
      method: "post",
      url: `${apiUrl}/deleteuploadsfile`,
      data: bodyFormData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(
        function (response) {
          console.log(response);
          if (response.status == 200) {
            return  toast.success("File has been deleted successfully");
          } else {
            return toast.error(response.message);
          }
        },
        (error) => {
          toast.error(error);
        }
      )
      .catch(function (error) {
        toast.error(error);
      });
  
}


// if(props.data==1){
//   setNumber(number + 1);
//   console.log(number);
//   fetchrecentuploads();
// }

// console.log(props.box);
if(props.box==1){
 
  document.getElementById("seachid").focus();
}
  return (
    <>
    
      {deleteselected ? (
        <div className="row Delete_div">
          <div className="col-6 button_left">
            <p className="delete_btn1">
              {selectedRows.length} items selected
            </p>
          </div>
          <div className="col-6 button_right text-right">
            <button className="delete_btn" onClick={deleteselecteditem}>
             Delete
            </button>
          </div>
        </div>
      ) : null}
      <DataTable
        columns={columns}
        data={filters}
        selectableRows
        selectableRowsHighlight
        highlightOnHover
        pagination
        customStyles={tableCustomStyles}
        // fixedHeader
        // fixedHeaderScrollHeight="400px"
        onSelectedRowsChange={handleChange}
        clearSelectedRows={toggledClearRows}
        subHeader
        subHeaderComponent={
          <input
          id="seachid"
            type="text"
            className="w-25 form-control"
            value={search}
            placeholder="search here "
            onChange={(e) => setSearch(e.target.value)}
          />
        }
      />
    </>
  );
}

export default uploadsfilelist;
