// export const apiUrl = '';
// export const domainUrl = '';

// export const apiUrl = 'http://15.206.144.233:8008/api/admin';
// export const domainUrl = 'http://15.206.144.233:8008/';

// export const apiUrl = 'http://127.0.0.1:8008/api/admin';
// export const domainUrl = 'http://127.0.0.1:8008/';

export const apiUrl = 'https://api.sagasjibavjiraj.com/api/admin';
export const domainUrl = 'https://api.sagasjibavjiraj.com/';



