import { useState, useEffect } from "react";
import defaultpdf from "../../assets/img/user/PDF_Image_svg.svg";
import {
  useHistory
} from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
  Input,
  TabContent,
  TabPane,
  Modal,
  ModalBody
} from "reactstrap";
const axios = require("axios");
import { authtoken } from "global.js";
import { apiUrl } from "../../constant";
import { toast } from "react-toastify";
import { bannerService } from "../../services/bannerService";
import { fileUpload } from "../../helpers/fileUpload";
import '../../helpers/globalClasses.css';


const Index = (props) => {
  const history = useHistory();
  const [bankDocuments, setBankDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [lastPage, setLastPageData] = useState(0);
  const [createModalOpen, setcreateModalOpen] = useState(false);
  const getPaginationData = async (pageNumber = 1, company = '') => {
    bannerService.getWithPaginate(pageNumber, company).then((response) => {
      setBankDetails(response.data.data.data);
      setCurrentPage(response.data.data.currentPage);
      setLastPageData(response.data.data.totalPage);
    });
  }

  const handlePageClick = (e, index) => {
    e.preventDefault();
    getPaginationData(index);
  };

  const handlePreviousClick = () => {
    getPaginationData(prev);
  };

  const handleNextClick = () => {
    getPaginationData(next);
  };

  const tableHeader = bannerService.tableHeader() ?? [];

  console.log("tableHeader", tableHeader)
  useEffect(() => {
    getPaginationData()
  }, []);


  const initialStateData = bannerService.initialFormData();

  const [inputFields, setInputfields] = useState(initialStateData);
  const onRespondClick = () => {
    var datasend = bannerService.transformFormData(inputFields)

    axios({
      method: "post",
      url: `${apiUrl}/banner/create`,
      data: datasend,
      headers: { Authorization: `${authtoken}` },

    })
      .then((response) => {
        if (response.status === 200) {
          setInputfields(initialStateData)
          setcreateModalOpen(false)
          toast.success(response.data.message);
          getPaginationData()
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        return error.response;
      });
  };


  const inputhandler = (e) => {
    if (e.target.type === "file") {
      handleFileChange(e);
    } else {
      setInputfields({ ...inputFields, [e.target.name]: e.target.value });
    }
  };

  const deleterow = (e) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((deletdata) => {
        if (deletdata) {
          bannerService.deleteById(e).then((response) => {
            if (response.data.code == 200) {
              const newList = bankDocuments.filter((item) => item._id !== e);
              setBankDetails(newList);
              toast.success('Deleted Successfully');
            } else {
              toast.error('Failed');
            }
          });
        }
      });

  };
  const handleclick = (e) => {
    history.push({
      pathname: 'editbanner/' + e,
    });
  }
  const [uploading, setUploading] = useState({
    image: false,
  });
  const [selectedFiles, setSelectedFiles] = useState({});
  const handleFileChange = async (e, field) => {
    setUploading((prevUploading) => ({ ...prevUploading, [field]: true }));

    try {
      let urls = await fileUpload(e.target.files);
      const files = urls[0];

      setSelectedFiles((prevFiles) => ({
        ...prevFiles,
        [field]: files,
      }));

      inputFields[field] = files;
      setInputfields({ ...inputFields, [field]: files });
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setUploading((prevUploading) => ({ ...prevUploading, [field]: false }));
    }
  };

  const chooseFileButtonStyle = {
    display: 'inline-block',
    backgroundColor: '#2DCE89',
    color: 'white',
    fontWeight: "bold",
    padding: '5px 15px',
    borderRadius: '30px',
    cursor: 'pointer',
  };



  const imagePreviewStyle = {
    position: 'relative',
    maxWidth: '40%',
    maxHeight: '200px',
    borderRadius: '50%',
    marginRight: '-4rem',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    margin: 'auto',
    transition: 'transform 0.3s, z-index 0.3s',
  };



  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader>
                <div className="align-items-center row">
                  <div className="col-8">
                    <Col lg="8" md="8" sm="8">
                      <h3 className="mb-0">Banner</h3>
                    </Col>
                  </div>
                  <div className="text-right col-4">
                    <button
                      className="btn btn-sm" style={{ backgroundColor: "#5e72e4", color: "#fff  " }}
                      onClick={() => setcreateModalOpen(true)}
                    >
                      Create {bannerService.moduleTitle()}
                    </button>
                  </div>
                </div>
              </CardHeader>
              <CardBody>

                <TabContent activeTab={"tabs1"}>
                  <TabPane tabId={"tabs1"}>
                    <Table className="align-items-center table-flush" responsive>
                      <thead className="thead-light">
                        <tr>
                          {
                            tableHeader.map((item, i) => (
                              <th scope="col">{item.title}</th>
                            ))
                          }
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bankDocuments.length > 0 && bankDocuments ? (
                          bankDocuments.map((trans, i) => (
                            <tr key={i}  >
                              {
                                tableHeader.map((item, value) => (
                                  <td key={value}>
                                    {item.value === 'image' ? ( // Check if it's an image type
                                      <img
                                        src={trans[item.value]} // Assuming the value is the image URL
                                        alt="images0pic"
                                        style={{ maxWidth: '100px' }} // Adjust the image size
                                      />
                                    ) : (
                                      trans[item.value]
                                    )}
                                  </td>
                                ))
                              }
                              <td>
                                <>
                                  <Button
                                    style={{ padding: '5px 8px', fontSize: '12px', width: "30px", height: "30px" }}
                                    className="btn btn-success"
                                    onClick={() => handleclick(trans._id)}
                                  >
                                    <i className="fa fa-edit"></i>
                                  </Button>
                                  <Button
                                    style={{ padding: '5px 8px', fontSize: '12px', width: "30px", height: "30px" }}
                                    className="btn btn-danger"
                                    onClick={() => deleterow(trans._id)}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </Button>
                                </>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td>loading.......</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </TabPane>


                </TabContent>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem disabled={currentPage <= 1}>
                        <PaginationLink onClick={handlePreviousClick} previous />
                      </PaginationItem>
                      {[...Array(lastPage)].map((page, i) => (
                        <PaginationItem
                          active={i + 1 === currentPage}
                          key={i + 1}
                        >
                          <PaginationLink
                            onClick={(e) => handlePageClick(e, i + 1)}
                          >
                            {i + 1}
                          </PaginationLink>
                        </PaginationItem>
                      ))}
                      <PaginationItem disabled={currentPage >= lastPage}>
                        <PaginationLink onClick={handleNextClick} next />
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </CardBody>
            </Card>
          </Col>
        </Row>

      </Container>
      <Modal className="taskdetailmodal" isOpen={createModalOpen} scrollable={true} style={{ width: "30%" }}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Create {bannerService.moduleTitle()}
          </h5>
          <button
            aria-label="Close"
            className="close"
            type="button"
            onClick={() => setcreateModalOpen(!createModalOpen)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalBody>
          <Row>

            {tableHeader.map((item, value) => (
              <Col lg={item.colWidth} key={value}>
                <FormGroup >
                  <label
                    className="form-control-label"
                    style={{ display: 'block', padding: '0px 0px' }}
                  >
                    {item.title}
                  </label>
                  {item.type === 'FILE' && item.value === 'image' && (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                      <label htmlFor={`fileInput_${item.value}`} style={chooseFileButtonStyle}>
                        Choose File
                      </label>
                      <input
                        id={`fileInput_${item.value}`}
                        type="file"
                        accept=".pdf, .jpg, .jpeg, .png, .gif"
                        onChange={(e) => handleFileChange(e, item.value)}
                        style={{ display: 'none' }}
                      />
                      {uploading.image && (
                        <div className="spinner-loader" >
                          <span className="sr-only">Loading...</span>
                        </div>
                      )}

                      {selectedFiles && selectedFiles.image && !uploading.image && (
                        <div>
                          {selectedFiles.image.endsWith('.pdf') ? (
                            <img
                              src={defaultpdf}
                              alt="Default Preview"
                              style={imagePreviewStyle}
                            />
                          ) : (
                            <img
                              src={selectedFiles.image}
                              alt="Preview"
                              style={imagePreviewStyle}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {item.type === 'SELECT' && item.value === 'status' && (
                    <Input
                      type="select"
                      className="form-control-alternative"
                      value={inputFields[item.value]}
                      onChange={inputhandler}
                      name={item.value}
                    >
                      <option value="" selected>Select Year</option>
                      <option value="active" selected>Active</option>
                      <option value="inactive">Inactive</option>
                      <option value="expired">Expired</option>
                      {item.options?.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Input>
                  )}

                  {item.type !== 'FILE' && item.type !== 'SELECT' && (
                    <Input
                      className="form-control-alternative"
                      defaultValue={inputFields[item.value]}
                      id={`input-${item.value}`}
                      placeholder={item.title}
                      name={item.value}
                      type="text"
                      onChange={inputhandler}
                    />
                  )}

                </FormGroup>
              </Col>
            ))}

          </Row>
          <FormGroup className="text-center">
            <Button
              color="success"
              size="extra-small"
              onClick={onRespondClick.bind(this)}
            >
              Create {bannerService.moduleTitle()}
            </Button>
          </FormGroup>
        </ModalBody>
      </Modal>



    </>
  );
};

export default Index;